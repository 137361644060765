
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "renewal-personal-accident-house-insurance",
  components: { Form, Field, ErrorMessage, TotalPremiumWidget, GoBackButton },
  data() {
    return {
      cartItemExists: false,
      displayForm: true,
      displayError: false,
      policies: [],
      policySummary: {},
      rate: 0.001,
      ratePublicId: "",
      medicalRate: 0.15,
      medicalRatePublicId: "",
      totalPremium: 0.0 as number,
      medicalExpensesValue: 0.0 as number,
      publicId: this.$route.params.publicId as string,
      housePolicyId: "",
      policy: "" as string,
      includeMedicalExpenses: false,
      isChecked: false,
      benefitLimit: 0.0 as number,
      result: {
        personalAccidentCartItemPublicId: "",
        hasPersonalAccidentCartItem: true || (false as boolean),
      },
      underwriter: "" as string,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const personalAccientValidator = Yup.object().shape({
      benefitLimit: Yup.string()
        .required()
        .label("Benefit limit"),
    });

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Submit data
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("benefitLimit", values["benefitLimit"]);
            formData.append("totalPremium", values["totalPremium"]);
            formData.append("policyId", values["housePolicyId"]);
            formData.append("includeMedicalExpenses", values["isChecked"]);
            formData.append("businessClass", "HOM");
            formData.append("businessType", "HOU");
            formData.append("package", "HPA");
            formData.append("endorsementType", "Renewal");
            formData.append("underwriter", values["underwriter"]);

            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Re-route to policy certificate upload page
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Renewal", [
        "Householders Insurance",
        "Personal Accident",
      ]);
    });

    return { submitButton1, personalAccientValidator, submitForm };
  },
  created() {
    this.hasExistingCartPackages();

    // Get personal accident house insurance policies only
    this.getPolicies();

    // Get the rates for personal accident package
    this.getRates();

    //Set page title
    document.title =
      "Renewal: Personal Accident Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/HPA/renewal`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
            this.cartItemExists = data.data.hasPersonalAccidentCartItem;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU/HPA`)
          .then(({ data }) => {
            //Assign data to props
            if (data.data != "") {
              this.displayError = false;
              this.policies = data.data;

              if (this.publicId !== undefined && this.publicId !== null) {
                this.policy = this.publicId;
                this.getPolicyInformation(this.publicId);
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.housePolicyId = value;

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_RENEWAL_ROUTE}/${value}`)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.totalPremium = data.data.policySummary.premium;
            this.benefitLimit = data.data.benefitLimit;
            this.includeMedicalExpenses = data.data.includeMedicalExpenses;
            this.isChecked = data.data.includeMedicalExpenses;
            this.underwriter = data.data.leadInsurer.publicId;

            if (data.data != "") {
              this.displayForm = true;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getRates() {
      //Fetch list of underwriters for house building insurance package
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_PERSONAL_ACCIDENT_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.rate = data.data.accidentalDeath.value;
            this.ratePublicId = data.data.accidentalDeath.id;
            this.medicalRate = data.data.reasonableMedicalExpenses.value;
            this.medicalRatePublicId = data.data.reasonableMedicalExpenses.id;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setTimeout(() => {
        this.$nextTick(() => (event.target.value = newValue));
      }, 0.1);

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value to the total premium field
      this.totalPremium = variables.totalPremiumValue();
      if (this.includeMedicalExpenses || this.isChecked) {
        this.isChecked = this.includeMedicalExpenses = !this
          .includeMedicalExpenses;
        this.medicalExpenses();
      }
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    medicalExpenses() {
      this.isChecked = this.includeMedicalExpenses = !this
        .includeMedicalExpenses;

      const paValue = document.getElementById(
        "personal-accident-value"
      ) as HTMLElement;

      const medicalExpensesPremiumText = document.getElementById(
        "medical-expenses-premium"
      ) as HTMLElement;

      const totalPremiumText = document.getElementById(
        "total-premium"
      ) as HTMLElement;

      if (this.includeMedicalExpenses) {
        const premiumValue = variables.removeComma(paValue.textContent);
        const value = this.medicalRate * premiumValue;
        const newPremium = variables.totalPremiumValue() + value;

        if (isNaN(newPremium) == false) {
          medicalExpensesPremiumText.textContent = this.addCommaToNumberString(
            value
          );
          this.totalPremium = newPremium;
          totalPremiumText.textContent = this.addCommaToNumberString(
            newPremium
          );
        }
      } else {
        this.totalPremium = variables.totalPremiumValue();
        medicalExpensesPremiumText.textContent = "0";
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
